import React, { Component } from 'react'
import { Container, Row, Alert, FormGroup, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import GoogleMapReact from 'google-map-react'
import ReCAPTCHA from "react-google-recaptcha"
import { Validate } from '../Validate'
import swal from 'sweetalert'
import Loading from '../Loading'
import Service from '../../service.js'
import { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import th from 'date-fns/locale/th'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import "react-image-gallery/styles/scss/image-gallery.scss"
import SmoothScrolling from './SmoothScroll';
import ScrollAnimation from 'react-animate-on-scroll';
import Footer from "../Common/footer";
// import Lightbox from 'react-images';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import "animate.css/animate.min.css";
registerLocale('th', th);


export default class ComponentHome extends Component {

    constructor(props) {
        super(props)
        this.date = new Date();
        this.state = {
            lightboxMapIsOpen: false,
            currentlightboxMap: 0,
            loading: false,
            alert: null,
            alertRFSP: null,
            date: [],
            selectDate: { date: 1, mount: "มกราคม", year: "2019", rangeDate: 31 },
            data: { time: "", firstname: "", lastname: "", tel: "", email: "", message: "", recaptcha: "", date_register: null },
            dataRFSP: { name: "", email: "", tel: "", recaptcha: "" },
            valid: {},
            validRFSP: {},
            select: "0",
            items: [],
            item_images: [],
            modal: false,
            Showbtnplay: true,
            lightboxPlanIsOpen: false,
            imgGallery: "",
            currentImage: 0,
            planCurrentImage: 0,
            lightboxProsIsOpen: false,
            prosCurrentImage: 0,
            isOpenMap: true,
            isModal: false
        }
        this.video_first_play = true;
        this.swiper_index = 0
        //this.swiper_autoplay = true
    }

    recaptchaRef = React.createRef()
    projectSlide = {
        slidesPerView: 1,
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        renderPrevButton: () => <div><i className="fas fa-chevron-left fa-2x text-white swiper-button-prev pl-3" /></div>,
        renderNextButton: () => <div><i className="fas fa-chevron-right fa-2x text-white swiper-button-next pr-5" /></div>,
    }
    indexImage = {
        planImg: [
            process.env.PUBLIC_URL + "/asset/images/section_plan/01-master_layout.jpg",
            process.env.PUBLIC_URL + "/asset/images/section_plan/02-layout_b.jpg",
            process.env.PUBLIC_URL + "/asset/images/section_plan/03-layout_a1.jpg",
            process.env.PUBLIC_URL + "/asset/images/section_plan/04-layout_a2.jpg",
        ],
        prosImg: [
            process.env.PUBLIC_URL + "/asset/images/layout1/sirivirin4.JPEG", 
            process.env.PUBLIC_URL + "/asset/images/layout1/sirivirin3.JPEG",
            process.env.PUBLIC_URL + "/asset/images/layout1/sirivirin1.JPEG",
            process.env.PUBLIC_URL + "/asset/images/layout1/sirivirin2.JPEG",
            process.env.PUBLIC_URL + "/asset/images/layout1/sirivirin5.JPEG",
            process.env.PUBLIC_URL + "/asset/images/image_pros/image_office.jpg"
        ],
        galleryImg: [
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin21.JPEG",
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin22.JPEG",
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin23.JPEG",
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin24.JPEG",
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin25.JPEG",
            process.env.PUBLIC_URL + "/asset/images/section_album/sirivirin26.JPEG"
        ]
    };

    params = () => {
        const _this = this
        return {
            slidesPerView: 1,
            initialSlide: _this.swiper_index,
            rebuildOnUpdate: true,
            shouldSwiperUpdate: true,
            autoplay: _this.state.Showbtnplay ?
                {
                    delay: 5000,
                    stopOnLast: true,
                    disableOnInteraction: true
                } : false,
            on: {
                slideChange: function () {
                    _this.swiper_index = this.activeIndex
                    if (this.isEnd) {
                        if (_this.video_first_play) {
                            _this.video.play()
                            _this.video_first_play = false
                        }
                        if (_this.state.Showbtnplay === false) {
                            this.autoplay.stop()
                        }

                    } else {
                        this.autoplay.start()
                    }
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            renderPrevButton: () => <div><i className="fas fa-chevron-left fa-2x text-white swiper-button-prev pl-3" /></div>,
            renderNextButton: () => <div><i className="fas fa-chevron-right fa-2x text-white swiper-button-next pr-5" /></div>,
        }
    }

    componentDidMount() {
        Service.gethouseplan()
            .then(response => {
                let getDateNow = this.state.selectDate
                getDateNow.date = this.date.getDate()
                getDateNow.mount = response.data.data[3].date[this.date.getMonth()]
                getDateNow.year = this.date.getFullYear()
                this.setState({ loading: false, items: response.data.data, item_images: response.data.data[0].images, date: response.data.data[3].date, selectDate: getDateNow })
            })
            .catch(error => {
                console.error(error)
            })
    }

    componentDidUpdate() {
        //console.clear()
    }

    getDate = (e, type) => {
        let getMonth = e.target.value
        let getState = this.state.selectDate
        if (type === "M") {
            let strDate = getMonth.substring(getMonth.length - 2, getMonth.length);
            getState.mount = getMonth
            if (strDate === "คม") {
                getState.rangeDate = 31
            } else if (strDate === "ยน") {
                getState.rangeDate = 30
            } else {
                if (parseInt(this.state.selectDate.year) % 4 === 0) {
                    getState.rangeDate = 29
                } else {
                    getState.rangeDate = 28
                }
            }
            this.setState({ selectDate: getState })
        }
    }

    date_registerChange = (date) => {
        this.setState({
            data: {
                time: this.state.data.time,
                firstname: this.state.data.firstname,
                lastname: this.state.data.lastname,
                tel: this.state.data.tel,
                email: this.state.data.email,
                message: this.state.data.message,
                recaptcha: this.state.data.recaptcha,
                date_register: date
            }
        })
    }

    onDismiss = () => { this.setState({ alert: null }) }

    handleEnter = (e) => { if (e.nativeEvent.keyCode === 13) { this.sendmail() } }

    getrecaptcha = (value) => {
        this.setState({
            data: {
                time: this.state.data.time,
                firstname: this.state.data.firstname,
                lastname: this.state.data.lastname,
                tel: this.state.data.tel,
                email: this.state.data.email,
                message: this.state.data.message,
                date_register: `${this.state.selectDate.date} ${this.state.selectDate.mount} ${parseInt(this.state.selectDate.year) + 543}`,
                recaptcha: value
            }
        })
    }

    getrecaptchaRFSP = (value) => {
        this.setState({
            dataRFSP: {
                name: this.state.data.name,
                tel: this.state.data.tel,
                email: this.state.data.email,
                recaptcha: value
            }
        })
    }

    valid = (e) => {
        this.onDismiss()
        const _data = this.state.data
        _data[e.target.name] = e.target.value

        const validate = Validate(this.state.data)
        if (validate.error !== null) {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ data: this.state.data, valid: { [validate.error.details[i].path[0]]: true } })
            }
        } else {
            this.setState({ data: this.state.data, valid: {} })
        }
    }

    validRFSP = (e) => {
        this.onDismiss()
        const _data = this.state.dataRFSP
        _data[e.target.name] = e.target.value

        const validate = Validate(this.state.dataRFSP)
        if (validate.error !== null) {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ data: this.state.dataRFSP, validRFSP: { [validate.error.details[i].path[0]]: true } })
            }
        } else {
            this.setState({ data: this.state.dataRFSP, validRFSP: {} })
        }
    }

    sendmail = () => {
        const _data = {
            time: this.state.data.time,
            firstname: this.state.data.firstname,
            lastname: this.state.data.lastname,
            tel: this.state.data.tel,
            email: this.state.data.email,
            message: this.state.data.message,
            recaptcha: this.state.data.recaptcha,
            date_register:
            {
                'datetime': this.state.data.date_register && this.state.data.date_register.toLocaleString(),
                'datetime_string': this.dateregister && this.dateregister.value
            },

        }
        const validate = Validate(_data)

        if (validate.error === null) {
            this.setState({ loading: true })
            Service.SendMail(_data)
                .then((responese) => {
                    swal({ title: "สำเร็จ", text: "คุณส่งข้อความเรียบร้อยแล้ว", icon: "success" })
                    this.onDismiss()
                    this.setState({ valid: {}, data: { time: "", firstname: "", lastname: "", message: "", email: "", tel: "", recaptcha: "", date_register: new Date() }, loading: false, isModal: false })
                })
                .catch(error => {
                    console.dir(error)
                    swal({ title: "เกิดข้อผิดพลาด", text: "เกิดข้อผิดพลาดขณะเรียกข้อมูลจากเซิร์ฟเวอร์", icon: "error", buttons: false })
                    this.setState({ loading: false })
                })

        } else {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ data: this.state.data, valid: { [validate.error.details[i].path[0]]: true } })
            }
            this.setState({ alert: validate.error.details[0].context.label })

            if (this.alert_danger) {
                // window.scroll({
                //     top: this.alert_danger.offsetTop - 150,
                //     behavior: "smooth"
                // });
                SmoothScrolling.scrollTo(this.alert_danger.attributes.id.value)
            }
        }
    }

    sendmailRFSP = () => {
        const _data = {
            name: this.state.dataRFSP.name,
            tel: this.state.dataRFSP.tel,
            email: this.state.dataRFSP.email,
            recaptcha: this.state.dataRFSP.recaptcha,
        }
        const validate = Validate(_data)
        console.log(validate)
        if (validate.error === null) {
            this.setState({ loading: true })
            Service.SendMailRFSP(_data)
                .then((responese) => {
                    swal({ title: "สำเร็จ", text: "คุณส่งข้อความเรียบร้อยแล้ว", icon: "success" })
                    this.onDismiss()
                    this.setState({ valid: {}, dataRFSP: { name: "", email: "", tel: "", recaptcha: "", }, loading: false, isModal: false })
                })
                .catch(error => {
                    console.dir(error)
                    swal({ title: "เกิดข้อผิดพลาด", text: "เกิดข้อผิดพลาดขณะเรียกข้อมูลจากเซิร์ฟเวอร์", icon: "error", buttons: false })
                    this.setState({ loading: false })
                })

        } else {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ dataRFSP: this.state.dataRFSP, validRFSP: { [validate.error.details[i].path[0]]: true } })
            }
            this.setState({ alertRFSP: validate.error.details[0].context.label })

            if (this.alert_danger_RFSP) {
                // window.scroll({
                //     top: this.alert_danger.offsetTop - 150,
                //     behavior: "smooth"
                // });
                SmoothScrolling.scrollTo(this.alert_danger_RFSP.attributes.id.value)
            }
        }
    }

    renderMarkers(map, maps) {
        new maps.Marker({
            position: { lat: 13.8038427, lng: 100.5068179 },
            map,
            title: 'Sirivirin'
        });
        this.props.PageHome.scrolllanding()
    }

    InputDatePicker = ({ value, onClick }) => {
        return (
            <input
                ref={(ref) => (this.dateregister = ref)}
                className="input-box"
                placeholder="นัดหมายเยี่ยมชม"
                readOnly={true}
                value={value}
                onClick={onClick}
            />
        );
    }

    changehouseplan = (e) => {
        this.setState({ select: e.target.value, item_images: this.state.items[e.target.value].images })
    }

    openLightbox = (size) => {
        document.body.style.overflow = "hidden";
        this.setState({ currentImage: size, lightboxIsOpen: true });
    }

    closeLightbox = () => {
        document.body.style.overflow = "auto";
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    openMap = (status) => {
        status ? this.setState({ isOpenMap: status }) : this.setState({ isOpenMap: status })
        // this.props.PageHome.chkScroll()
    }

    toggle = () => { this.setState(prevState => ({ modal: !prevState.modal })) }

    funcDate = (rangeDate) => {
        let ele = '';
        for (let ld = 0; ld <= rangeDate - 1; ld++) {
            ele += `<option ${(this.date.getDate() === (ld + 1)) && "selected"}>${ld + 1}</option>`
        }
        return (ele);

    }

    monthFunc = (rangeDate) => {
        let ele = '';
        for (let ld = 0; ld <= rangeDate; ld++) {
            ele += `<option ${ld === 0 && "selected"}>${(this.date.getFullYear() + ld) + 543}</option>`
        }
        return (ele);

    }
    
    styles_bg = {
        height:'500px', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'
    }

    styles_mobile = {
        height:'500px',overflow:'hidden', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'
    }

    render() {

        return (
            <div style={{ height: "100%" }}>
                <Loading loading={this.state.loading} />
                {/* <div className="position-fixed bottom-0 right-0" style={{zIndex:"99999"}}>
                    <i className="fas fa-phone fs-20 pr-2 align-self-center"></i>
                </div> */}
                <section ref={this.props.PageHome.home} className="reps-section padding-nav">
                    <div className="swiper-wrapper-banner h-100">
                        <Swiper  {...this.params()}>
                            <div key="0" className="bg-banner-1 d-flex justify-content-center align-items-center" >
                                <img src={process.env.PUBLIC_URL + "/asset/images/section_banner/banner.JPG"} className="img-fluid d-none d-sm-block w-100" alt="" />
                                <img src={process.env.PUBLIC_URL + "/asset/images/section_banner/banner.JPG"} className="img-fluid d-block d-sm-none w-100" alt="" />
                            </div>
                            {/* <div key="0" className="" >
                                <div className="bg-banner-1 w-100 img-banner-1" />
                                <div className="w-100 text-banner-1-1">ซ.จรัญสนิทวงศ์ 95/1 เริ่ม 8.9 ลบ.*</div>
                                <div className="w-100 text-banner-1-2">รับส่วนลดเงินสด สูงสุด 500,000 บาท</div>
                            </div> */}
                            <div key="1" className="w-100 reps-banner">
                                <div className={`h-100 ${this.state.Showbtnplay ? "bg-cover-vdo" : " "}`}>
                                    <div className="position-absolute w-100 h-100 iframe-main">
                                        {this.state.Showbtnplay ? <div className="play-main h-100 w-100" ><a onClick={() => { this.video.play() }} ><i className="fas fa-play-circle fa-3x text-dark-blue" /></a></div> : ""}
                                        <div className={`embed-responsive embed-responsive-16by9 w-100 h-100 ${this.state.Showbtnplay ? "d-none" : "d-block"}`}>
                                            <video poster={process.env.PUBLIC_URL + "/asset/images/section_banner/bg-desktop.jpg"} controls controlsList="nodownload" ref={(e) => { this.video = e }} /*onLoadStart={() => this.video.play()}*/ onPause={() => this.setState({ Showbtnplay: true })} onPlaying={() => this.setState({ Showbtnplay: false })} muted>
                                                <source src={process.env.PUBLIC_URL + "/asset/images/Sirivirin_Draft_1.mp4"} type="video/mp4" />
                                                <source src={process.env.PUBLIC_URL + "/asset/images/Sirivirin_Draft_1.ogg"} type="video/ogg" />
                                                <source src={process.env.PUBLIC_URL + "/asset/images/Sirivirin_Draft_1.webm"} type="video/webm" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="w-100 d-flex justify-content-center">
                                <img src={process.env.PUBLIC_URL+"/asset/images/section_banner/bg-smart-phone 2.jpg"} alt="" className="img-fluid"/>
                            </div> */}
                        </Swiper>
                    </div>
                    {/* <div className="bg-dark-blue w-100 d-flex justify-content-center align-items-center" style={{ height: 55 }}>
                        <NavLink to="/register"><Button outline color="secondary" className="rounded-0 border-light text-white-u bg-dark-blue py-0 px-4 hov2" >ลงทะเบียนเพื่อเข้าชม</Button></NavLink>
                    </div> */}
                </section>

                <section ref={this.props.PageHome.register} className="bg-white h-auto">
                    <Container ref={(ref) => (this.alert_danger_RFSP = ref)} id="mail-alert-rfsp" className="py-0 px-0 py-5">
                        <h1 className="pb-3 text-dark header-1 text-center">ลงทะเบียนรับสิทธิพิเศษ</h1>
                        <div className="px-5">
                            <Alert className="text-center" color="danger" isOpen={this.state.alertRFSP !== null} toggle={this.onDismiss}>
                                <i className="fas fa-exclamation-triangle pr-3" />{this.state.alertRFSP}
                            </Alert>
                        </div>
                        <Row className="w-100 mx-0 justify-content-center">
                            <div className="col-lg-6">
                                <FormGroup className={'validate-input ' + (this.state.validRFSP.name ? 'alert-validate' : '')} data-validate="ชื่อ นามสกุล ไม่ถูกต้อง">
                                    <input className="input-box" value={this.state.dataRFSP.name} type="text" name="name" placeholder="ชื่อ - นามสกุล..." onChange={this.validRFSP} onKeyPress={this.handleEnter} />
                                    <span className="focus-input-box"></span>
                                    <span className="symbol-input-box"></span>
                                </FormGroup>
                            </div>
                        </Row>
                        <Row className="w-100 mx-0 justify-content-center">
                            <div className="col-lg-6">
                                <FormGroup className={'validate-input ' + (this.state.validRFSP.email ? 'alert-validate' : '')} data-validate="อีเมลไม่ถูกต้อง">
                                    <input className="input-box" value={this.state.dataRFSP.email} type="email" name="email" placeholder="อีเมล์..." onChange={this.validRFSP} onKeyPress={this.handleEnter} />
                                    <span className="focus-input-box"></span>
                                    <span className="symbol-input-box"></span>
                                </FormGroup>
                            </div>
                        </Row>
                        <Row className="w-100 mx-0 justify-content-center">
                            <div className="col-lg-6">
                                <FormGroup className={'validate-input ' + (this.state.validRFSP.tel ? 'alert-validate' : '')} data-validate="เบอร์โทรศัพท์ไม่ถูกต้อง">
                                    <input className="input-box" type="tel" value={this.state.dataRFSP.tel} name="tel" placeholder="เบอร์โทรศัพท์..." onChange={this.validRFSP} onKeyPress={this.handleEnter} />
                                    <span className="focus-input-box"></span>
                                    <span className="symbol-input-box"></span>
                                </FormGroup>
                            </div>
                        </Row>
                        <Row className="w-100 mx-0">
                            <div className="col-12 order-lg-8 order-sm-8">
                                <FormGroup className="w-100 d-flex justify-content-center">
                                    <ReCAPTCHA onChange={this.getrecaptchaRFSP} ref={this.recaptchaRef} sitekey="6LcmHnoUAAAAADQykIbH-RqQ3t4CdkYVr-V9pYQ8" className="" />
                                </FormGroup>
                            </div></Row>
                        <Row className="w-100 mx-0">
                            <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 d-flex justify-content-center">
                                <div className="col-12 px-3 mx-0">
                                    <FormGroup>
                                        <a onClick={() => { this.sendmailRFSP() }} color="" className="d-block rounded-0 bg-dark-blue text-white d-block mx-auto border px-5 fs-14 py-0 hov2 btn btn-">ลงทะเบียนรับสิทธิพิเศษ</a>
                                    </FormGroup>
                                </div>
                            </div>
                        </Row>
                        <Row className="w-100 mx-0">
                            <div className="col-12 col-md-8 col-lg-6 d-flex flex-wrap center offset-md-2 offset-lg-3">
                                <div className="col-md-6 px-3 mx-0">
                                    <FormGroup>
                                        <a className="d-block rounded-0 bg-dark-blue text-white d-block mx-auto border px-3 fs-14 py-0 hov2 btn btn-" target="_blank" href="tel:0993639797" ><i className="fas fa-phone fs-20 align-self-center pt-2"></i><span>&nbsp;&nbsp; 099-363-9797</span></a>
                                    </FormGroup>
                                </div >

                                <div className="col-md-6 px-3 mx-0">
                                    <FormGroup>
                                        <a className="d-block rounded-0 bg-dark-blue text-white d-block mx-auto border px-3 fs-14 py-0 hov2 btn btn-" target="_blank" href="https://line.me/R/ti/p/%40sirivirinestate"><i className="fab fa-line fs-20 align-self-center pt-2"></i><span>&nbsp;&nbsp; @sirivirinestate</span></a>
                                    </FormGroup>
                                </div >
                            </div>
                        </Row>
                    </Container>
                </section>

                <section ref={this.props.PageHome.concept} className="bg-image1 align-items-center d-flex">
                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true} className="bg-text-block-content">
                        <p className="text-white text-center-vertical text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Functional Value Townhome ที่สุดของความคุ้มค่าใน ทำเล  ฟังก์ชั่นการใช้งาน และคุณภาพ
ที่ถูกออกแบบ และสร้างสรรค์ด้วยความใส่ใจในคุณภาพทุกขั้นตอน เพื่อการอยู่อาศัยแบบมีความสุขอย่างยั่งยืน
 <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;สิริวิรินทร์ ทาวน์โฮม เราคำนึงถึงคุณค่าที่ลูกค้าจะได้รับจากทุกวันของการอยู่อาศัย ด้วยสุดยอดทำเลศักยภาพห้อมล้อมด้วยสาธารณูปโภคด้านการคมนาคมที่ครบครัน การออกแบบฟังก์ชั่น<span className="d-inline d-sm-inline d-md-none"> </span>การใช้งาน และสร้างสรรค์โครงการด้วยความใส่ใจในคุณภาพทุกขั้นตอน ทำให้สิริวิรินทร์ ทาวน์โฮม เหมาะกับครอบครัวที่ต้องการเวลาแห่งความสุขที่มากขึ้น อีกทั้งยังเหมาะกับนักธุรกิจ SMEs ที่มองหาโฮมออฟฟิศ ที่สะดวกสบายในการติดต่อธุรกิจและอยู่อาศัย</p>
                    </ScrollAnimation>
                </section>

                <section className="bg-white-op auto-height" ref={this.props.PageHome.location}>
                    <Row className="m-0 d-none d-xl-flex">
                        <div className="col-12 col-lg-6 px-0 h-50">
                            <Row className="w-100 mx-0 align-items-center" style={{height:'500px'}}>
                                <div className="w-100 d-block py-5 px-3 display_pros text-dark-blue">
                                    <h3 className="text-center ">จุดเด่นโครงการ</h3>
                                    <div className="py-2 py-xl-4">
                                        <div className="underline-head w-50 bg-dark-blue d-block m-auto" />
                                    </div>
                                    <div className="w-100 d-flex justify-content-center">
                                        <ul className="house-type d-inline-block">
                                            <li>ห้อง Living Room ที่ให้ความโปร่งโล่ง Double Space กับฝ้าเพดาน สูงถึง 5 เมตร</li>
                                            <li>สวนส่วนตัว พื้นที่สีเขียวเชื่อมต่อกับห้องรับประทานอาหาร</li>
                                            <li>ห้องอเนกประสงค์ ปรับได้ตามความต้องการ  </li>
                                            <li>ห้องครัว ออกแบบให้แยกส่วนออกจากตัวบ้าน </li>
                                            <li>ชั้นลอย สำหรับเป็นโฮมออฟฟิศ พร้อมระบบ LAN, WIFI </li>
                                            <li>กล้อง CCTV เพื่อให้คุณอุ่นใจ เพราะทุกอย่างอยู่ในสายตา</li>
                                        </ul>
                                    </div>
                                </div>
                                <img src="https://via.placeholder.com/960x540" className="img-fluid d-lg-block d-none" alt="" style={{ visibility: "hidden" }} />
                            </Row>
                            <Row className="w-100 mx-0">
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 1 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin3.JPEG')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-04.jpg"} style={{height:"28vw"}} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">สวนส่วนตัว พื้นที่สีเขียวเชื่อมต่อกับห้อง<br />รับประทานอาหาร รับแสง และลมธรรมชาติอย่างสบาย</p></div>
                                    </div>
                                </div>
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 0 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin4.JPEG')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-hilight.jpg"} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">ห้องอเนกประสงค์ ปรับได้ตามความต้องการ ไว้นั่งเล่น หรือ เพื่อคนที่ต้องการการดูแลเป็นพิเศษ </p></div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-lg-6 px-0">
                            <Row className="w-100 mx-0">
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 2 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin1.JPEG')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-01.jpg"} style={{height:"28vw"}} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">ห้องครัว ที่ได้รับการออกแบบให้แยกส่วนออกจากตัวบ้าน หมดปัญหากลิ่นควันรบกวน</p></div>
                                    </div>
                                </div>
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 3 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin2.JPEG')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-02.jpg"} style={{height:"28vw"}} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">ห้อง Living Room ที่ให้ความโปร่งโล่ง Double Space กับฝ้าเพดาน สูงถึง 5 เมตร</p></div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="w-100 mx-0">
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 4 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin5.JPEG')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-05.jpg"} style={{height:"28vw"}} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">ชั้นลอย ที่เหมาะกับการสร้างโฮมออฟฟิศ เป็นสัดส่วน พร้อมระบบ LAN, WIFI กระจายสัญญาณได้ทั่วบ้าน</p></div>
                                    </div>
                                </div>
                                <div className="col-6 bg-pros px-0 h-auto" onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 5 }) }}>
                                    <div className="" style={{...this.styles_bg, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/image_Hilight/06-hilight.jpg')`}}>
                                        {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/06-hilight.jpg"} className="img-fluid w-100 w-lg-auto pointer" alt="" /> */}
                                        <div style={{ position: "absolute", top: "0" }} className="px-3 h-100 d-flex align-items-center content-pros-hover w-100"><p className="text-center w-100">ระบบรักษาความปลอดภัยด้วยกล้อง CCTV เพื่อให้คุณอุ่นใจ เพราะทุกอย่างอยู่ในสายตา</p></div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <div className="d-block d-xl-none">
                        <div className="col-xl-7 w-100 d-block ml-auto py-5 px-3 display_pros text-dark-blue">
                            <div className="w-100 d-block  px-3 display_pros text-dark-blue">
                                <h3 className="text-center ">จุดเด่นโครงการ</h3>
                                <div className="py-2 py-xl-4">
                                    <div className="underline-head w-50 bg-dark-blue d-block m-auto" />
                                </div>
                                <div className="w-100 d-flex justify-content-center">
                                    <ul className="house-type d-inline-block">
                                        <li>ห้อง Living Room ที่ให้ความโปร่งโล่ง Double Space กับฝ้าเพดาน สูงถึง 5 เมตร</li>
                                        <li>สวนส่วนตัว พื้นที่สีเขียวเชื่อมต่อกับห้องรับประทานอาหาร</li>
                                        <li>ห้องอเนกประสงค์ ปรับได้ตามความต้องการ  </li>
                                        <li>ห้องครัว ออกแบบให้แยกส่วนออกจากตัวบ้าน </li>
                                        <li>ชั้นลอย สำหรับเป็นโฮมออฟฟิศ พร้อมระบบ LAN, WIFI </li>
                                        <li>กล้อง CCTV เพื่อให้คุณอุ่นใจ เพราะทุกอย่างอยู่ในสายตา</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Row className="mx-0">
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin3.JPEG')` }} onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 1 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">สวนส่วนตัว พื้นที่สีเขียวเชื่อมต่อกับห้องรับประทานอาหาร รับแสง และลมธรรมชาติอย่างสบาย</p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/01-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin4.JPEG')` }} onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 0 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">ห้องอเนกประสงค์ ปรับได้ตามความต้องการ ไว้นั่งเล่น หรือ เพื่อคนที่ต้องการการดูแลเป็นพิเศษ </p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/02-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin1.JPEG')` }} onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 2 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">ห้องครัว ที่ได้รับการออกแบบให้แยกส่วนออกจากตัวบ้าน หมดปัญหากลิ่นควันรบกวน</p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/03-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin2.JPEG')` }} onClick={(e) => { document.body.style.overflow = "hidden"; document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 3 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">ห้อง Living Room ที่ให้ความโปร่งโล่ง Double Space กับฝ้าเพดาน สูงถึง 5 เมตร</p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/04-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/layout1/sirivirin5.JPEG')` }} onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 4 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">ชั้นลอย ที่เหมาะกับการสร้างโฮมออฟฟิศ เป็นสัดส่วน พร้อมระบบ LAN, WIFI กระจายสัญญาณได้ทั่วบ้าน</p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/05-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                            <div className="d-flex align-items-center position-relative col-12 col-sm-6 px-0" style={{ ...this.styles_mobile, backgroundImage:`url(${process.env.PUBLIC_URL}'/asset/images/image_Hilight/06-hilight.jpg')` }} onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxProsIsOpen: true, prosCurrentImage: 5 }) }}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={false} className="position-absolute w-100 h-100">
                                    <div className="hover-pros h-100 d-flex align-items-center"><p className="px-3">ระบบรักษาความปลอดภัยด้วยกล้อง CCTV เพื่อให้คุณอุ่นใจ เพราะทุกอย่างอยู่ในสายตา</p></div>
                                </ScrollAnimation>
                                {/* <img src={process.env.PUBLIC_URL + "/asset/images/image_Hilight/06-hilight.jpg"} className="img-fluid w-100" alt="" /> */}
                            </div>
                        </Row>
                    </div>
                </section>
                {/* Position House */}
                {/* <section ref={this.props.PageHome.project} style={{ "height": `${window.innerHeight}px` }} className="bg-white-op"> */}
                <section className="bg-white-op auto-height">
                    <Container className="py-0 px-0 py-5">
                        <div className="pt-5">
                            <Swiper {...this.projectSlide} className="swip-project">
                                <div className="px-md-5">
                                    <div className="col-12 col-lg-6 d-block m-auto pb-5 text-dark-blue">
                                        <h2 className="py-3 text-center">แสงธรรมชาติ</h2>
                                        <p className="lh-1-3 text-center">เพียงพอสำหรับทุกพื้นที่ ประหยัดพลังงานไฟฟ้า</p>
                                    </div>
                                    <img className="w-75 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/image_Function/01-light-function.png"} alt=""></img>
                                </div>
                                <div className="px-md-5">
                                    <div className="col-12 col-lg-6 d-block m-auto pb-5 text-dark-blue">
                                        <h2 className="py-3 text-center">รับลมธรรมชาติ</h2>
                                        <p className="lh-1-3 text-center">ประตูบานกว้างเชื่อมต่อสวน รับลมและอากาศธรรมชาติ</p>
                                    </div>
                                    <img className="w-75 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/image_Function/02-wind-function.png"} alt=""></img>
                                </div>
                                <div className="px-md-5">
                                    <div className="col-12 col-lg-6 d-block m-auto pb-5 text-dark-blue">
                                        <h2 className="py-3 text-center">ห้องครัว</h2>
                                        <p className="lh-1-3 text-center">เชื่อมต่อด้านหลังบ้าน แยกครัวไทยออกจากตัวบ้าน ป้องกันปัญหากลิ่นและควันเวลาทำอาหาร</p>
                                    </div>
                                    <img className="w-75 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/image_Function/01-kitchen-function.png"} alt=""></img>
                                </div>
                                <div className="px-md-5">
                                    <div className="col-12 col-lg-6 d-block m-auto pb-5 text-dark-blue">
                                        <h2 className="py-3 text-center">สวนส่วนตัว</h2>
                                        <p className="lh-1-3 text-center">เชื่อมต่อกับส่วนรับประทานอาหารเพิ่มพื้นที่สีเขียว เพื่อการพักหย่อนใจ</p>
                                    </div>
                                    <img className="w-75 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/image_Function/01-graden-function.png"} alt=""></img>
                                </div>
                            </Swiper>
                        </div>
                    </Container>
                </section>

                <section ref={this.props.PageHome.project} className="bg-dark-blue auto-height">
                    <Container className="px-3 py-5 px-lg-0">
                        <h2 className="text-center text-white pb-4">ที่ตั้งโครงการ</h2>
                        <div className="underline-head col-lg-4 bg-white d-block m-auto" />
                        <Row className="text-center text-white pt-5 mx-0">
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="far fa-hospital fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center">500 ม.</p>
                                    <p className="text-center">5 นาที</p>
                                    <small className="fs-16">ถึง โรงพยาบาลยันฮี</small>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="far fa-building fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center">7.5 กม.</p>
                                    <p className="text-center">13 นาที</p>
                                    <small className="fs-16">ถึง  ห้างสรรพสินค้าเซ็นทรัลปิ่นเกล้า</small>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="far fa-building fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center">4 กม.</p>
                                    <p className="text-center">7 นาที</p>
                                    <small className="fs-16">ถึง ห้างสรรพสินค้าเกตเวย์ บางซ่ือ</small>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="fas fa-road fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center">100 ม.</p>
                                    <p className="text-center">1 นาที</p>
                                    <small className="fs-16">ถึง ทางด่วนพิเศษศรีรัช<br />วงแหวนรอบนอก</small>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="fas fa-subway fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center"> 1 กม.</p>
                                    <p className="text-center">3 นาที</p>
                                    <small className="fs-16">ถึง รถไฟฟ้าสายสีน้ำเงิน<br />สถานีบางอ้อ</small>
                                </div>
                            </div>
                            <div className="col-6 col-lg-2 lh-1-3 py-3">
                                <i className="fas fa-subway fs-45"></i>
                                <div className="pt-4">
                                    <p className="text-center">4 กม.</p>
                                    <p className="text-center">6 นาที</p>
                                    <small className="fs-16">ถึง รถไฟฟ้าสายสีแดงอ่อน<br />สถานีบางกรวย-กฟผ.</small>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6 offset-md-3 d-lg-flex d-block pt-5">
                                <Button color="" className={`rounded-0 bg-dark-blue text-white px-5 my-3 my-lg-0 fs-14 py-0 border mr-lg-3 d-block mx-auto hov2 ${this.state.isOpenMap && 'bg-white text-dark-blue'}`} onClick={(e) => { this.openMap(true) }}>Map Graphic</Button>
                                <Button color="" className={`rounded-0 bg-dark-blue text-white px-5 my-3 my-lg-0 fs-14 py-0 border ml-lg-3 d-block mx-auto hov2 ${!this.state.isOpenMap && 'bg-white text-dark-blue'}`} onClick={(e) => { this.openMap(false) }}>Google Map</Button>
                            </div>
                        </Row>
                    </Container>
                    {/* <div className="bg-map" onClick={(e) => { if (this.state.isOpenMap) { document.body.style.overflow = "hidden"; this.setState({ lightboxMapIsOpen: true });} }}>
                        <div className={!this.state.isOpenMap ? `d-block w-100 h-100` : `d-none w-100 h-100`} >
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyCheRot8a2v6ihzAPqlpIFZNe4atTnX444" }}
                                defaultCenter={{ lat: 13.8038427, lng: 100.5068179 }}
                                defaultZoom={16}
                                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                                yesIWantToUseGoogleMapApiInternals={true} >
                            </GoogleMapReact>
                        </div>
                    </div> */}
                    <Container>
                        <img src={process.env.PUBLIC_URL + "/asset/images/map.jpeg"} className='img-fluid'/>
                    </Container>
                </section>


                <section className="bg-dark-op auto-height" ref={this.props.PageHome.house}>
                    <div className="px-md-0 pt-5 h-100 container-gobold container-lg">
                        <h2 className="text-center text-dark-blue pb-lg-4 pb-0">PLAN TYPE B</h2>
                        <Row className="h-97-8 mx-0">
                            <div className="col-lg-4 order-lg-1 order-1 px-0">
                                <div className="bg-dark-blue text-white h-auto h-lg-100" style={{ overflow: "hidden" }}>
                                    <ScrollAnimation animateIn='fadeInLeft' className="h-100 align-items-center d-flex" animateOnce={true}>
                                        <div className="fs-20 py-3 px-5 font-weight-lighter">
                                            <h5>PLAN TYPE B</h5>
                                            <p className="text-left fs-20">
                                                ยูนิตขนาดมาตรฐานของโครงการ ด้วยการออกแบบ Style Modern Luxury เน้นความคุ้มค่า และการใช้วัสดุชั้นดี เพื่อคงคุณค่าในการบำรุงรักษาในระยะยาว สร้างความสุขแก่ครอบครัวได้จากรุ่นสู่รุ่น
                                            </p>
                                            <ul className="lh-2-3 house-type d-block detail-plan">
                                                <li>ที่ดิน 30 ตร.ว. </li>
                                                <li>พื้นที่ใช้สอย 270 ตร.ม.  </li>
                                                <li>ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </li>
                                                <li>ชั้นลอย  </li>
                                                <li>3 ห้องนอน 4 ห้องน้ำ 2 ที่จอดรถ </li>
                                            </ul>
                                        </div>
                                    </ScrollAnimation>
                                    {/* <table className="table table-borderless table-center-detail-house d-lg-none my-0 text-white fs-20">
                                        <tbody>
                                            <tr>
                                                <td>- ที่ดิน 30 ตร.ว. </td>
                                                <td>- พื้นที่ใช้สอย 270 ตร.ม. </td>
                                            </tr>
                                            <tr>
                                                <td>- ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </td>
                                                <td>- ชั้นลอย</td>
                                            </tr>
                                            <tr>
                                                <td>- 3 ห้องนอน 4 ห้องน้ำ 2 ที่จอดรถ</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-0 order-0 px-5 pb-5 px-lg-3">
                                <Row>
                                    <div className="col-lg-6 offset-lg-6 pt-3 pb-4 pt-lg-0">
                                        <div className="underline-head bg-dark-blue d-block m-auto w-100" />
                                    </div>
                                </Row>
                                <div className="h-lg-100 h-auto">
                                    <div className="text-lg-center-vertical">
                                        <img className="w-100 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/section_plan/02-layout_b.jpg"} alt=""></img>
                                        <Button onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxPlanIsOpen: true, planCurrentImage: 1 }) }} color="" className={`rounded-0 bg-white-op ${this.state.lightboxPlanIsOpen ? "text-white" : "text-dark-blue"} px-5 fs-14 py-0 mx-auto d-block mt-5 border-dark-bule hov1 ${this.state.lightboxPlanIsOpen && "text-white bg-dark-blue"}`}>view plan</Button>

                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </section>

                <section className="bg-dark-blue auto-height">
                    <div className="px-md-0 pt-5 h-100 container-gobold container-lg">
                        <h2 className="text-center text-white pb-lg-4 pb-0">PLAN TYPE A1</h2>
                        <Row className="h-97-8 mx-0">
                            <div className="col-lg-4 order-lg-0 px-0 order-1 pr-lg-1">
                                <div className="bg-white-op h-auto h-lg-100" style={{ overflow: "hidden" }}>
                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true} className="h-100 align-items-center d-flex" style={{ overflow: "hidden" }}>
                                        <div className="fs-20 py-3 px-5 font-weight-lighter">
                                            <h5>PLAN TYPE A1</h5>
                                            <p className="text-left fs-20">
                                                ห้องริมที่กว้างและมีพื้นที่มากที่สุด เน้นความคุ้มค่าของพื้นที่ประโยชน์ใช้สอย พื้นที่สีเขียวที่มากขึ้นด้านข้าง และที่จอดรถจำนวนมากขึ้นถึง 6 คัน ด้วยการออกแบบ Style Modern Luxury โครงสร้างจึงเหมาะแก่การเป็นที่ทำงาน และ ที่บ้านของครอบครัวนักธุรกิจ ที่พร้อมต้อนรับลูกค้าเสมอ
                                            </p>
                                            <ul className="lh-2-3 house-type d-block detail-plan">
                                                <li>ห้องริม ที่ดิน 62 ตร.ว. </li>
                                                <li>พื้นที่ใช้สอย 393 ตร.ม.  </li>
                                                <li>ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </li>
                                                <li>ชั้นลอย  </li>
                                                <li>3 ห้องนอน 4 ห้องน้ำ 6 ที่จอดรถ </li>
                                            </ul>
                                        </div>
                                    </ScrollAnimation>
                                    {/* <table className="table table-borderless table-center-detail-house d-lg-none my-0 fs-20" style={{ color: "gray" }}>
                                        <tbody>
                                            <tr>
                                                <td>- ห้องริม ที่ดิน 62 ตร.ว. </td>
                                                <td>- พื้นที่ใช้สอย 393 ตร.ม.</td>
                                            </tr>
                                            <tr>
                                                <td>- ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </td>
                                                <td>- ชั้นลอย </td>
                                            </tr>
                                            <tr>
                                                <td>- 3 ห้องนอน 4 ห้องน้ำ 6 ที่จอดรถ</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-1 order-0 pl-lg-1 px-5 px-lg-3 pb-5 pt-0">
                                <Row>
                                    <div className="col-lg-6 pr-lg-1 pt-3 pb-4 py-lg-0">
                                        <div className="underline-head bg-white d-block m-auto w-100" />
                                    </div>
                                </Row>
                                <div className="h-lg-100 h-auto">
                                    <div className="text-lg-center-vertical">
                                        <img className="w-100 d-block pt-3 m-auto" src={process.env.PUBLIC_URL + "/asset/images/section_plan/03-layout_a1.jpg"} alt=""></img>
                                        <Button onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxPlanIsOpen: true, planCurrentImage: 2 }) }} color="" className={`rounded-0 bg-dark-blue text-white px-5 fs-14 py-0 border mx-auto d-block mt-5 hov2 ${this.state.lightboxPlanIsOpen && "text-dark-blue bg-white"}`}>view plan</Button>

                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </section>

                <section className="bg-dark-op auto-height">
                    <div className="px-md-0 pt-5 h-100 container-gobold container-lg">
                        <h2 className="text-center text-dark-blue pb-lg-4 pb-0">PLAN TYPE A2</h2>
                        <Row className="h-97-8 mx-0">
                            <div className="col-lg-4 order-lg-1 order-1 px-0">
                                <div className="bg-dark-blue text-white h-auto h-lg-100">
                                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true} className="h-100 align-items-center d-flex" style={{ overflow: "hidden" }}>
                                        <div className="fs-20 py-3 px-5 font-weight-lighter">
                                            <h5>PLAN TYPE A2</h5>
                                            <p className="text-left fs-20">
                                                ห้องริมที่กว้างและมีพื้นที่รองลงมา ยังคงให้ความคุ้มค่าของพื้นที่ประโยชน์ใช้สอย พื้นที่สีเขียวที่มากขึ้นด้านข้าง และที่จอดรถจำนวนมากขึ้นถึง 5 คัน เหมาะสำหรับครอบครัวขนาดใหญ่ที่ต้องการพื้นที่ส่วนตัวมากขึ้น และ มีพื้นที่สร้างกิจกรรมในครอบครัวร่วมกัน
                                            </p>
                                            <ul className="lh-2-3 house-type d-block detail-plan">
                                                <li>ห้องริม ที่ดิน 50.2 ตร.ว. </li>
                                                <li>พื้นที่ใช้สอย 346 ตร.ม.  </li>
                                                <li>ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </li>
                                                <li>ชั้นลอย  </li>
                                                <li>3 ห้องนอน 4 ห้องน้ำ 5 ที่จอดรถ </li>
                                            </ul>
                                        </div>

                                    </ScrollAnimation>
                                    {/* <table className="table table-borderless text-white table-center-detail-house d-lg-none my-0 fs-20">
                                        <tbody>
                                            <tr>
                                                <td>- ห้องริม ที่ดิน 50.2 ตร.ว. </td>
                                                <td>- พื้นที่ใช้สอย 346 ตร.ม.</td>
                                            </tr>
                                            <tr>
                                                <td>- ห้องอเนกประสงค์ ห้องครัว สวนส่วนตัว </td>
                                                <td>- ชั้นลอย</td>
                                            </tr>
                                            <tr>
                                                <td>- 3 ห้องนอน 4 ห้องน้ำ 5 ที่จอดรถ</td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                </div>
                            </div>
                            <div className="col-lg-8 order-lg-0 order-0 px-5 pb-5 px-lg-3">
                                <Row>
                                    <div className="col-lg-6 offset-lg-6 pt-3 pb-4 py-lg-0">
                                        <div className="underline-head bg-dark-blue d-block m-auto w-100" />
                                    </div>
                                </Row>
                                <div className="h-lg-100 h-auto">
                                    <div className="text-lg-center-vertical">
                                        <img className="w-100 d-block m-auto" src={process.env.PUBLIC_URL + "/asset/images/section_plan/04-layout_a2.jpg"} alt=""></img>
                                        <Button onClick={(e) => { document.body.style.overflow = "hidden"; this.setState({ lightboxPlanIsOpen: true, planCurrentImage: 3 }) }} color="" className={`rounded-0 bg-white-op ${this.state.lightboxPlanIsOpen ? "text-white" : "text-dark-blue"} px-5 fs-14 py-0 mx-auto d-block mt-5 border-dark-bule hov1 ${this.state.lightboxPlanIsOpen && "text-white bg-dark-blue"}`}>view plan</Button>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </section>

                <section ref={this.props.PageHome.gallery} className="bg-dark-blue section-album">
                    <Container className="py-5">
                        <h2 className="text-center text-white pb-4">อัลบั้มภาพ</h2>
                        <div className="px-3 px-lg-0"><div className="underline-head col-lg-4 bg-white d-block m-auto" /></div>
                        <div className="pt-5">
                            <Row className="py-lg-1 mx-0">
                                <img className="col-lg-6 px-lg-1 img-fluid pointer gellery-img" onClick={() => this.openLightbox(0)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/01-album-new.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer d-none d-lg-block gellery-img" onClick={() => this.openLightbox(1)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/02-album-new.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer h-100 d-block d-lg-none" onClick={() => this.openLightbox(1)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/02-album-new-mobile.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer d-none d-lg-block gellery-img" onClick={() => this.openLightbox(2)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/03-album-new.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer h-100 d-block d-lg-none" onClick={() => this.openLightbox(2)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/03-album-new-mobile.jpg"} alt=""></img>
                            </Row>
                            <Row className="py-lg-1 mx-0">
                                <img className="col-lg-3 px-lg-1 img-fluid pointer d-none d-lg-block gellery-img" onClick={() => this.openLightbox(3)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/04-album-new.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer h-100 d-block d-lg-none" onClick={() => this.openLightbox(3)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/04-album-new-mobile.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer d-none d-lg-block gellery-img" onClick={() => this.openLightbox(4)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/05-album-new.jpg"} alt=""></img>
                                <img className="col-lg-3 px-lg-1 img-fluid pointer h-100 d-block d-lg-none" onClick={() => this.openLightbox(4)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/05-album-new-mobile.jpg"} alt=""></img>
                                <img className="col-lg-6 px-lg-1 img-fluid pointer gellery-img" onClick={() => this.openLightbox(5)} src={process.env.PUBLIC_URL + "/asset/images/section_album/jpeg/06-album-new.jpg"} alt=""></img>
                            </Row>
                        </div>
                    </Container>
                </section>

                <section className="auto-height bg-dark-blue">
                    <div className="bg-white-op h-lg-100">
                        <Container className="py-0 px-0">
                            <div ref={(ref) => (this.alert_danger = ref)} id="mail-alert" className="py-5 px-md-5">
                                <div className="text-center pb-3 px-lg-0 px-5">
                                    <h1 className="pb-3 text-dark header-1">ติดต่อเพื่อเยี่ยมชมโครงการ</h1>
                                    <div className="underline-head col-lg-4 bg-dark-blue d-block mx-auto"></div>
                                    <p className="text-center pt-3 text-dark-blue"> ติดต่อนัดเวลาเพื่อเข้าชมโครงการ ตามหลักการป้องกัน มาตราฐาน covid-19</p>
                                </div>
                                <div className="px-5">
                                    <Alert className="text-center" color="danger" isOpen={this.state.alert !== null} toggle={this.onDismiss}>
                                        <i className="fas fa-exclamation-triangle pr-3" />{this.state.alert}
                                    </Alert>
                                </div>
                                <Row className="pt-3 pb-5 px-5 w-100 mx-0">
                                    <div className="col-md-6 order-lg-1 order-md-1 order-sm-1">
                                        <span className="text-dark-blue">วันที่ที่คุณต้องการเข้าชม</span>
                                        <div className="row justify-content-between">
                                            <FormGroup className="col-3 select-box pr-0">
                                                <select type="select" name="select" id="exampleSelectMulti" className="w-100" dangerouslySetInnerHTML={{ __html: this.funcDate(this.state.selectDate.rangeDate) }}>

                                                </select>
                                            </FormGroup>
                                            <FormGroup className="col-6 select-box">
                                                <select type="select" name="select" value={this.state.selectDate.mount} id="exampleSelectMulti" className="w-100" onChange={(e) => { this.getDate(e, 'M') }}>
                                                    {
                                                        this.state.date.map((items, index) => {
                                                            return (
                                                                <option key={index} value={items}>{items}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </FormGroup>
                                            <FormGroup className="col-3 select-box pl-0">
                                                <select type="select" name="select" id="exampleSelectMulti" className="w-100" dangerouslySetInnerHTML={{ __html: this.monthFunc(5) }}>

                                                </select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="col-md-6 order-lg-2 order-md-2 order-sm-2">
                                        <span className="text-dark-blue">เวลาเริ่มต้นที่คุณต้องการเข้าชม</span>
                                        <FormGroup className={'validate-input ' + (this.state.valid.time && 'alert-validate') + ' ' + (!this.state.valid.time && 'icon-input-clock')} data-validate="เวลาถูกไม่ต้อง">
                                            <input className="input-box" value={this.state.data.time} type="time" name="time" onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box"></span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6 order-lg-3 order-md-3 order-sm-3">
                                        <FormGroup className={'validate-input ' + (this.state.valid.firstname ? 'alert-validate' : '')} data-validate="ชื่อไม่ถูกต้อง">
                                            <input className="input-box" value={this.state.data.firstname} type="text" name="firstname" placeholder="ชื่อ..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box"></span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6 order-lg-4 order-md-4 order-sm-4">
                                        <FormGroup className={'validate-input ' + (this.state.valid.lastname ? 'alert-validate' : '')} data-validate="นามสกุลไม่ถูกต้อง">
                                            <input className="input-box" value={this.state.data.lastname} type="text" name="lastname" placeholder="นามสกุล..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box"></span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6 order-lg-5 order-md-6 order-sm-6">
                                        <FormGroup className={'validate-input ' + (this.state.valid.email ? 'alert-validate' : '')} data-validate="อีเมลไม่ถูกต้อง">
                                            <input className="input-box" type="email" name="email" value={this.state.data.email} placeholder="อีเมล์..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box"></span>
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-6 order-lg-6 order-md-5 order-sm-5">
                                        <FormGroup className={'validate-input ' + (this.state.valid.tel ? 'alert-validate' : '')} data-validate="เบอร์ติดต่อไม่ถูกต้อง">
                                            <input className="input-box" type="tel" value={this.state.data.tel} name="tel" placeholder="เบอร์โทรศัพท์..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box"></span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 order-lg-7 order-md-7 order-sm-7">
                                        <FormGroup className={'validate-input ' + (this.state.valid.message ? 'alert-validate' : '')} data-validate="กรอกรายละเอียด">
                                            <textarea className="textarea-box" type="text" name="message" placeholder="เขียนข้อความถึงโครงการ..." value={this.state.data.message} onChange={this.valid} onKeyPress={this.handleEnter} />
                                            <span className="focus-input-box"></span>
                                            <span className="symbol-input-box align-items-start pt-4"></span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 order-lg-8 order-md-8 order-sm-8">
                                        <FormGroup className="w-100 d-flex justify-content-center">
                                            <ReCAPTCHA onChange={this.getrecaptcha} ref={this.recaptchaRef} sitekey="6LcmHnoUAAAAADQykIbH-RqQ3t4CdkYVr-V9pYQ8" className="" />
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 order-lg-9 order-md-9 order-sm-9">
                                        <FormGroup>
                                            <Button onClick={() => { this.sendmail() }} color="" className="rounded-0 bg-dark-blue text-white d-block mx-auto border px-5 fs-14 py-0 hov2">ส่ง</Button>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </section>
                <div ref={this.props.PageHome.contact}>
                    <Footer />
                </div>
                {
                    this.state.lightboxIsOpen &&
                    <Lightbox
                        mainSrc={this.indexImage.galleryImg[this.state.currentImage]}
                        // images={indexImage}
                        // isOpen={this.state.lightboxIsOpen}
                        onCloseRequest={() => {
                            document.body.style.overflow = "auto"
                            this.setState({ lightboxIsOpen: false })
                        }}
                    />
                }
                {
                    this.state.lightboxPlanIsOpen &&
                    <Lightbox
                        mainSrc={this.indexImage.planImg[this.state.planCurrentImage]}
                        // images={indexImage}
                        // isOpen={this.state.lightboxIsOpen}
                        onCloseRequest={() => {
                            document.body.style.overflow = "auto"
                            this.setState({ lightboxPlanIsOpen: false })
                        }}
                    />
                }
                {
                    this.state.lightboxProsIsOpen &&
                    <Lightbox
                        mainSrc={this.indexImage.prosImg[this.state.prosCurrentImage]}
                        // images={indexImage}
                        // isOpen={this.state.lightboxIsOpen}
                        onCloseRequest={() => {
                            document.body.style.overflow = "auto"
                            this.setState({ lightboxProsIsOpen: false })
                        }}
                    />
                }
                {
                    this.state.lightboxMapIsOpen &&
                    <Lightbox
                        mainSrc={process.env.PUBLIC_URL + '/asset/images/section_map/image_map_graphic-detail.jpg'}
                        // images={indexImage}
                        // isOpen={this.state.lightboxIsOpen}
                        onCloseRequest={() => {
                            document.body.style.overflow = "auto"
                            this.setState({ lightboxMapIsOpen: false })
                        }}
                    />
                }
                <Modal isOpen={this.state.isModal} size="lg" className="h-100 d-flex align-items-center m-t-200 m-b-200 my-lg-0" toggle={() => { this.setState({ isModal: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isModal: false }) }} >

                    </ModalHeader>
                    <div className="text-center pb-3 px-lg-0 px-5">
                        <h1 className="pb-3 text-dark header-1">ติดต่อเพื่อเยี่ยมชมโครงการ</h1>
                        <div className="underline-head col-lg-4 bg-dark-blue d-block mx-auto"></div>
                        <p className="text-center pt-3 text-dark-blue"> ติดต่อนัดเวลาเพื่อเข้าชมโครงการ ตามหลักการป้องกัน มาตราฐาน covid-19</p>
                    </div>
                    <ModalBody>
                        <div ref={(ref) => (this.alert_danger = ref)} id="mail-alert">
                            <div className="px-5">
                                <Alert className="text-center" color="danger" isOpen={this.state.alert !== null} toggle={this.onDismiss}>
                                    <i className="fas fa-exclamation-triangle pr-3" />{this.state.alert}
                                </Alert>
                            </div>
                            <Row className="w-100 mx-0">
                                <div className="col-lg-6 order-lg-1 order-sm-1">
                                    <span className="text-dark-blue">วันที่ที่คุณต้องการเข้าชม</span>
                                    <div className="row justify-content-between">
                                        <FormGroup className="col-3 select-box pr-0">
                                            <select type="select" name="select" id="exampleSelectMulti" className="w-100" dangerouslySetInnerHTML={{ __html: this.funcDate(this.state.selectDate.rangeDate) }}>

                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-6 select-box">
                                            <select type="select" name="select" value={this.state.selectDate.mount} id="exampleSelectMulti" className="w-100" onChange={(e) => { this.getDate(e, 'M') }}>
                                                {
                                                    this.state.date.map((items, index) => {
                                                        return (
                                                            <option key={index} value={items}>{items}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </FormGroup>
                                        <FormGroup className="col-3 select-box pl-0">
                                            <select type="select" name="select" id="exampleSelectMulti" className="w-100" dangerouslySetInnerHTML={{ __html: this.monthFunc(5) }}>

                                            </select>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-2 order-sm-2">
                                    <span className="text-dark-blue">เวลาเริ่มต้นที่คุณต้องการเข้าชม</span>
                                    <FormGroup className={'validate-input ' + (this.state.valid.time && 'alert-validate') + ' ' + (!this.state.valid.time && 'icon-input-clock')} data-validate="เวลาถูกไม่ต้อง">
                                        <input className="input-box" value={this.state.data.time} type="time" name="time" onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box"></span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6 order-lg-3 order-sm-3">
                                    <FormGroup className={'validate-input ' + (this.state.valid.firstname ? 'alert-validate' : '')} data-validate="ชื่อไม่ถูกต้อง">
                                        <input className="input-box" value={this.state.data.firstname} type="text" name="firstname" placeholder="Enter your Firstname..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box"></span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6 order-lg-4 order-sm-4">
                                    <FormGroup className={'validate-input ' + (this.state.valid.lastname ? 'alert-validate' : '')} data-validate="นามสกุลไม่ถูกต้อง">
                                        <input className="input-box" value={this.state.data.lastname} type="text" name="lastname" placeholder="Enter your Lastname..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box"></span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6 order-lg-5 order-sm-6">
                                    <FormGroup className={'validate-input ' + (this.state.valid.email ? 'alert-validate' : '')} data-validate="อีเมลไม่ถูกต้อง">
                                        <input className="input-box" type="email" name="email" value={this.state.data.email} placeholder="Enter your Email Address..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box"></span>
                                    </FormGroup>
                                </div>

                                <div className="col-lg-6 order-lg-6 order-sm-5">
                                    <FormGroup className={'validate-input ' + (this.state.valid.tel ? 'alert-validate' : '')} data-validate="เบอร์ติดต่อไม่ถูกต้อง">
                                        <input className="input-box" type="tel" value={this.state.data.tel} name="tel" placeholder="Enter your Phone number..." onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box"></span>
                                    </FormGroup>
                                </div>
                                <div className="col-12 order-lg-7 order-sm-7">
                                    <FormGroup className={'validate-input ' + (this.state.valid.message ? 'alert-validate' : '')} data-validate="กรอกรายละเอียด">
                                        <textarea className="textarea-box" type="text" name="message" placeholder="Enter your Message..." value={this.state.data.message} onChange={this.valid} onKeyPress={this.handleEnter} />
                                        <span className="focus-input-box"></span>
                                        <span className="symbol-input-box align-items-start pt-4"></span>
                                    </FormGroup>
                                </div>
                                <div className="col-12 order-lg-8 order-sm-8">
                                    <FormGroup className="w-100 d-flex justify-content-center">
                                        <ReCAPTCHA onChange={this.getrecaptcha} ref={this.recaptchaRef} sitekey="6LcmHnoUAAAAADQykIbH-RqQ3t4CdkYVr-V9pYQ8" className="" />
                                    </FormGroup>
                                </div>
                                <div className="col-12 order-lg-9 order-sm-9">
                                    <FormGroup>
                                        <Button onClick={() => { this.sendmail() }} color="" className="rounded-0 bg-dark-blue text-white d-block mx-auto border px-5 fs-14 py-0 hov2">Send</Button>
                                    </FormGroup>
                                </div>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        )
    }
}
