import React, { Component } from 'react'
import { NavLink } from 'reactstrap';

export default class Footer extends Component {
    render() {
        return (
            <section className="auto-height">
                <footer className="page-footer font-small mdb-color darken-3 pt-4 bg-dark-blue text-white">
                    <div className="container">
                        <div className="row d-flex justify-content-center pb-4">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/asset/images/logo_sirivirin.svg"} alt="" width="114px" />
                        </div>
                        <div className="row d-flex px-5 justify-content-center pb-4">
                            <p className="fs-18 text-center text-white">จรัญสนิทวงศ์ 95/1 แขวงบางอ้อ เขตบางพลัด กรุงเทพมหานคร</p>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="d-flex align-items-center ">
                                <div className="col-auto d-lg-flex justify-content-lg-center">
                                    <div className="col-lg-auto py-lg-0 py-2 justify-content-lg-center d-flex text-white">
                                        <a href="tel:0993639797" className="text-white">
                                            <i className="fas fa-phone fs-20 pr-2 align-self-center"></i>
                                            099 363 9797
                                        </a>
                                    </div >
                                    <div className="col-lg-auto py-lg-0 py-2 justify-content-lg-center d-flex">
                                        <a href="mailto:sirivirinestate@gmail.com" className="text-white">
                                            <i className="fas fa-envelope fs-20 pr-2 align-self-center"></i>
                                            sirivirinestate@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-auto py-3"><h5 className="text-center">Follow</h5></div>
                            <div className="col-md-auto py-3 d-flex justify-content-center align-items-center">
                                <div className="px-3 d-flex align-items-center">
                                    <a href="https://line.me/R/ti/p/%40sirivirinestate" className="d-flex justify-content-center align-items-center text-white" target="_blank" rel="noopener noreferrer"><i className="fab fa-line fs-25"></i></a>
                                </div >
                                <div className="px-3 d-flex align-items-center text-white">
                                    <a href="https://www.facebook.com/sirivirinestate" className="d-flex justify-content-center align-items-center text-white" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fs-25"></i></a>
                                </div >
                                <div className="px-3 d-flex align-items-center text-white">
                                    <a href="https://www.instagram.com/sirivirin_estate" className="d-flex justify-content-center align-items-center text-white" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fs-25"></i></a>
                                </div >
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright text-center py-1 font-weight-light">
                        © 2019 Sirivirin Estate. Design by <a target="_blank" href="https://www.itreelife.com"><span style={{ fontSize: 14 + "pt" }}>Itreelife.</span></a></div>
                </footer>
            </section>
        )
    }
}
