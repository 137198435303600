import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './style/util.scss'
import './style/main.scss'
import PageHome from './container/home'
import PageNotfound from './component/Common/notfound'
import Pagepdpa from './container/pdpa';
import { Switch, Route } from 'react-router-dom'


export default class App extends Component {

  render() {
    if(window.location.pathname == `${process.env.PUBLIC_URL}/pdpa`){
      return(
        <div style={{height:"100%"}}>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/pdpa`} component={Pagepdpa} />
            <Route component={PageNotfound} />
          </Switch>
        </div>
      )
    }else{
      return (
        <div style={{height:"100%"}}>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/concept`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/highlight`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/location`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/plan`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/register`} component={PageHome} />
            <Route exact path={`${process.env.PUBLIC_URL}/pdpa`} component={Pagepdpa} />
            <Route component={PageNotfound} />
          </Switch>
        </div>
      );
    }
  }
}