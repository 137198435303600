import React, { Component } from 'react'
import './style.scss'
import '../../../style/main.scss'
import {
    Collapse,
    Nav,
    NavItem
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

export default class Callapse extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        // document.addEventListener('click', this.handleDocumentClick);
        // document.addEventListener('touchend', this.handleDocumentClick);
    }

    componentWillUnmount() {
        // document.removeEventListener('click', this.handleDocumentClick);
        // document.removeEventListener('touchend', this.handleDocumentClick);
    }

    handleDocumentClick = (e) => {
        // if (e.target.className.indexOf("nav-link") <= -1 && e.target.className.indexOf("navbar-toggler") <= -1) {
        //     this.props.closemenu()
        //     console.log(e.target)
        // }
    }
    onClickrea = (e) => {
        // console.log(e.target.className.indexOf("callapse-area"))

        if (e.target.className.indexOf("callapse-area") === 0) {
            this.props.closemenu()
        }
    }

    render() {
        return (
            <div className={this.props.open ? "callapse-area w-100 h-100" : "d-none"} onClick={(e) => this.onClickrea(e)}>
                <div className="callapse-new">
                    <Collapse ref={(c) => (this._element = c)} isOpen={this.props.open} navbar>
                        <Nav className="ml-auto mr-xl-0 mr-auto" navbar>
                            <NavItem>
                                <NavLink exact to={process.env.PUBLIC_URL} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/" ? " menu-collapse-active" : "")}>หน้าแรก</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/concept"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/concept" ? " menu-collapse-active" : "")}>แนวคิดโครงการ</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/highlight"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/highlight" ? " menu-collapse-active" : "")}>จุดเด่นโครงการ</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/location"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/location" ? " menu-collapse-active" : "")}>ที่ตั้งโครงการ</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavLink exact to={process.env.PUBLIC_URL + "/plan"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/plan" ? " menu-collapse-active" : "")}>แบบบ้าน</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/gallery"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/gallery" ? " menu-collapse-active" : "")}>อัลบั้มภาพ</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/contact"} className={"nav-link py-sm-4 px-xl-4 px-2 d-flex justify-content-center" + (window.location.pathname === "/contact" ? " menu-collapse-active" : "")}>ติดต่อเรา</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink exact to={process.env.PUBLIC_URL + "/register"} className="nav-link py-3 px-xl-4 px-2  d-flex justify-content-center align-items-center h-100"><span className="px-5 btn-regis bg-dark-blue text-white">ลงทะเบียน</span></NavHashLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </div>
        )
    }
}