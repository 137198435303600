import React, { Component } from "react";
import Footer from "../component/Common/footer";
import Menu from "../component/Common/menu";

export default class Pagepdpa extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Menu PageHome={this} />
        <div className="pt-5 container">
test
        </div>
        <Footer/>
      </div>
    );
  }
}
