import axios from 'axios'
// 
// const apiPath = "http://localhost:8080";
// const apiPath = "https://www.itreelife.com/sirivirin"; //api-server-test
const apiPath = "https://www.sirivirin.com"; //api-server
const Service = {
  SendMail(e) {
    return axios.post(apiPath + '/Sendmail', e, { headers: { 'Content-Type': 'application/json' } })
  }, SendMailRFSP(e) {
    return axios.post(apiPath + '/Sendmail/rfsp.php', e, { headers: { 'Content-Type': 'application/json' } })
  }, gethouseplan() {
    return axios.get('./asset/api/houseplan.json')
  }
}

export default Service