import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router, } from 'react-router-dom'

ReactDOM.render((
  <Router basename='/'>
    <App />
  </Router>
), document.getElementById('root'))
