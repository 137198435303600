import React, { Component } from "react";
import Menu from "../component/Common/menu";
import Home from "../component/Home";

export default class PageHome extends Component {
  constructor(props) {
    super(props);
    this.home = React.createRef();
    this.concept = React.createRef();
    this.location = React.createRef();
    this.project = React.createRef();
    this.house = React.createRef();
    this.gallery = React.createRef();
    this.contact = React.createRef();
    this.register = React.createRef();
    this.state = {
      isscrollmove: false
    };
  }

  componentDidUpdate() {
    this.scrolllanding();
  }
  listenerscroll = () => {
    window.onscroll = () => {
      // console.log( 9452*(9.5/100), document.documentElement.scrollHeight*(((this.location.current.offsetHeight*100)/document.documentElement.scrollHeight)/100)   )
      if (
        document.documentElement.scrollHeight * (((this.home.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) - 220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL) {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL }, "", process.env.PUBLIC_URL);
          this.setState({ isscrollmove: true });
          // console.log("home");
        }
      } else if (
        this.concept.current.offsetTop +
        document.documentElement.scrollHeight * (((this.concept.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/concept") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/concept" }, "", process.env.PUBLIC_URL+"/concept");
          this.setState({ isscrollmove: true });
          // console.log("concept");
        }
      } else if (
        this.location.current.offsetTop +
        document.documentElement.scrollHeight * (((this.location.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/highlight") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/highlight" }, "", process.env.PUBLIC_URL+"/highlight");
          this.setState({ isscrollmove: true });
          // console.log("location");
        }
      } else if (
        this.project.current.offsetTop +
        document.documentElement.scrollHeight * (((this.project.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/location") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/location" }, "", process.env.PUBLIC_URL+"/location");
          this.setState({ isscrollmove: true });
          // console.log("project");
        }
      } else if (
        this.house.current.offsetTop +
        document.documentElement.scrollHeight * ((((this.house.current.offsetHeight * 3) * 100) / document.documentElement.scrollHeight) / 100) - 220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/plan") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/plan" }, "", process.env.PUBLIC_URL+"/plan");
          this.setState({ isscrollmove: true });
          // console.log("house");
        }
      } else if (
        this.gallery.current.offsetTop +
        document.documentElement.scrollHeight * (((this.gallery.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/gallery") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/gallery" }, "", process.env.PUBLIC_URL+"/gallery");
          this.setState({ isscrollmove: true });
          // console.log("gallery");
        }
      } else if (
        this.contact.current.offsetTop +
        document.documentElement.scrollHeight * (((this.contact.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/contact") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/contact" }, "", process.env.PUBLIC_URL+"/contact");
          this.setState({ isscrollmove: true });
          // console.log("contact");
        }
      } else if (
        this.register.current.offsetTop +
        document.documentElement.scrollHeight * (((this.register.current.offsetHeight * 100) / document.documentElement.scrollHeight) / 100) -
        220 >
        window.pageYOffset
      ) {
        if (window.location.pathname !== process.env.PUBLIC_URL+"/register") {
          window.history.pushState({ urlPath: process.env.PUBLIC_URL+"/register" }, "", process.env.PUBLIC_URL+"/register");
          this.setState({ isscrollmove: true });
          // console.log("register");
        }
      }
    };
  };
  scrolllanding = () => {
    var _top = 0;
    window.onscroll = null;
    if (this.state.isscrollmove) {
      this.listenerscroll();
      delete this.state.isscrollmove;
    } else {
      if(window.location.pathname !== `${process.env.PUBLIC_URL}/pdpa` ){
        switch (window.location.pathname) {
          case `${process.env.PUBLIC_URL}`:
            _top = 0;
            break;
          case `${process.env.PUBLIC_URL}/concept`:
            _top = this.concept.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/highlight`:
            _top = this.location.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/location`:
            _top = this.project.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/plan`:
            _top = this.house.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/gallery`:
            _top = this.gallery.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/contact`:
            _top = this.contact.current.offsetTop;
            break;
          case `${process.env.PUBLIC_URL}/register`:
            _top = this.register.current.offsetTop;
            break;
          default:
              _top = 0;
            break;
        }
      }
      
      window.scroll({
        top: _top <= 0 ? 0 : _top - 53,
        behavior: "smooth"
      });

      window.onscroll = e => {
        window.clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(() => {
          this.listenerscroll();
        }, 70);
      };
    }
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Menu PageHome={this} />
        <Home PageHome={this} />
      </div>
    );
  }
}
