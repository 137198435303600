import React, { Component } from 'react'
import './style.scss'
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  Button
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import Callapse from '../menu/callapse';

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      mobileactive: false
    }
  }

  toggle = () => { this.setState({ isOpen: !this.state.isOpen }) }


  closemenu = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <div className="top-menu">
        <Navbar fixed="top" color="light" expand="lg" className="py-lg-0 py-4 shadow-heavy menu-container py-0">
          <div className="container">
            <NavLink to="/" className="logo-sirivirin"><img className="img-fluid" src={process.env.PUBLIC_URL+"/asset/images/logo_sirivirin.svg"} alt="" width="90px" /></NavLink>
            {/* <NavbarToggler onClick={this.toggle}/> */}
            <div onClick={this.toggle} className={"nave-toggle navbar-toggler " + (this.state.isOpen ? "bg-white" : "")}><i className={"fas " + (this.state.isOpen ? "fa-times dark-blue" : "fa-bars text-white")}></i></div>
            <Collapse navbar>
              <Nav className="pl-lg-5 px-0 d-flex justify-content-between w-100" navbar>
                <NavItem>
                  <NavLink exact to={process.env.PUBLIC_URL} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL? " menu-active" : "")}>
                    <p className="fs-18">หน้าแรก</p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/concept"}  className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/concept" ? " menu-active" : "")}> <p className="fs-18">แนวคิดโครงการ</p></NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/highlight"} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/highlight" ? " menu-active" : "")}><p className="fs-18">จุดเด่นโครงการ</p></NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/location"} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/location" ? " menu-active" : "")}><p className="fs-18">ที่ตั้งโครงการ</p></NavHashLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to={process.env.PUBLIC_URL+"/plan"} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/plan" ? " menu-active" : "")}><p className="fs-18">แบบบ้าน</p></NavLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/gallery"} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/gallery" ? " menu-active" : "")}><p className="fs-18">อัลบั้มภาพ</p></NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/contact"} className={"nav-link py-2 text-white d-flex justify-content-center" + (window.location.pathname === process.env.PUBLIC_URL+"/contact" ? " menu-active" : "")}><p className="fs-18">ติดต่อเรา</p></NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink exact to={process.env.PUBLIC_URL+"/register"} className={"nav-link py-2 text-white d-flex justify-content-center"}><Button outline color="secondary" className="rounded-0 border-light text-white-u bg-dark-blue py-0 px-4 hov2">ลงทะเบียนรับสิทธิพิเศษ</Button></NavHashLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
        <Callapse open={this.state.isOpen} closemenu={this.closemenu} />
      </div>
    )
  }
}
