import Joi from 'joi-browser'

const param = Joi.object().keys({
    time:Joi.string().label('กรุณาใส่เวลาของคุณ'),
    name: Joi.string().label('กรุณาใส่ชื่อนามสกุนของคุณ'),
    firstname: Joi.string().label('กรุณาใส่ชื่อของคุณ'),
    lastname: Joi.string().label('กรุณาใส่นามสกุลของคุณ'),
    email: Joi.string().email({ minDomainAtoms: 2 }).label('กรุณาใส่อีเมลของคุณ'),
    tel: Joi.number().label('กรุณาใส่เบอร์โทรศัพท์ของคุณ'),
    date_register: Joi.object().label('กรุณาเลือกวันที่ขอเข้าชม'),
    message: Joi.string().label('กรุณาใส่ข้อความของคุณ'),
    recaptcha: Joi.string().label('กรุณาคลิกยืนยันว่าคุณไม่ใช่บอท'),
})

export function Validate(obj) {
    return Joi.validate(obj, param)
}